namespace $.$$ {
	export class $hyoo_crus_app extends $.$hyoo_crus_app {
		
		@ $mol_mem
		auto() {
			this.$.$hyoo_crus_glob.home()
		}
		
	}
}
