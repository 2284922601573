namespace $.$$ {
	
	$mol_style_define( $hyoo_crus_auth_slot, {
		
		flex: {
			basis: '15rem',
		},
		
		Key: {
			font: {
				family: 'monospace',
			},
		},
		
	} )
	
}
