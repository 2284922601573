$hyoo_crus_glob_book $mol_book2_catalog
	menu_title \🌐 Glob
	param \ref
	Spread* <= Land* $hyoo_crus_land_page
		land <= land* $hyoo_crus_land
		node <= node* $hyoo_crus_dict
		Close <= Spread_close
	menu_foot /
		<= Wipe_pick $mol_pick
			hint \Wipe database
			clicks? <=> wipe? null
			align_hor \right
			trigger_content /
				<= Wipe_icon $mol_icon_delete_forever
			bubble_content /
				\Double to wipe database
		<= Update $mol_button_open
			hint \Upload dump
			files? <=> update? /
		<= Land_add $hyoo_crus_land_grab
			hint \Grab new Land
			align_hor \left
			grab? <=> land_add? null $hyoo_crus_rank_preset
