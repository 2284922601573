namespace $.$$ {
	
	$mol_style_define( $hyoo_crus_sand_dump, {
		
		Other: {
			padding: $mol_gap.text,
		},
		
	} )
	
}
