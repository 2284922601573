$hyoo_crus_land_page $mol_page
	land $hyoo_crus_land
	node $hyoo_crus_dict
	tools /
		<= Encrypted $mol_check_icon
			hint \Encryption
			Icon <= Encrypted_icon $mol_icon_lock
			checked <= encrypted false
			enabled <= encrypted false
		<= Close null
	body /
		<= Flex $hyoo_crus_flex_form
			node <= node
		<= Raw $mol_labeler
			Content <= Raw_content $mol_list rows /
				<= Raw_data $hyoo_crus_node_dump
					title \Data
					node <= node
				<= Raw_meta $hyoo_crus_node_dump
					title \Tine
					node <= node_meta $hyoo_crus_node
		- <= Text $mol_textarea
			value? <=> text? \
			selection? <=> selection? /
				0
				0
	foot /
		<= Fork $mol_button_minor
			hint \Fork this land
			sub / <= Fork_icon $mol_icon_source_fork
			click? <=> fork? null
		<= Dumping $mol_view sub /
			<= Size $mol_view sub / <= size \0 KB
			<= Dump $mol_button_download
				hint \Download dump
				blob <= dump $mol_blob
				file_name <= dump_name \AAAAAAAA_AAAAAAAA.land
