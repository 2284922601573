$hyoo_crus_flex_field $mol_view
	sub / <= Sub $mol_view
	node? $hyoo_crus_node
	land = node land
	prop $hyoo_crus_flex_prop
	Enum $mol_select
		enabled <= enabled true
		value? <=> enum? null
		options <= enum_options /
		option_label* <= enum_label* \
	Bool $mol_check_box
		enabled <= enabled true
		checked? <=> bool? false
	Int $mol_number
		enabled <= enabled true
		value? <=> int? +NaN
	Real $mol_number
		enabled <= enabled true
		value? <=> real? +NaN
	Ref $mol_bar sub <= ref_content /
		<= Ref_dump $hyoo_crus_sand_dump
			land <= land
			value <= ref_value null
		<= Ref_pick $mol_select
			enabled <= enabled true
			value? <=> ref? null
			options <= ref_options /
			option_label* <= ref_label* \
		<= Ref_new $mol_select
			Filter null
			trigger_content /
				<= Ref_new_icon $mol_icon_plus
			value? <=> ref_new? null
			dictionary *
				local @ \💼 Local
				orgy @ \✍ All write
				lobby @ \📢 All join
				public @ \🔎 All read
				private @ \🔐 Private
	Str $mol_textarea
		enabled <= enabled true
		value? <=> str? \
	Time $mol_date
		enabled <= enabled true
		value_moment? <=> time? $mol_time_moment
	Dict $mol_expander
		title <= dict_title \
		content / <= Dict_form $hyoo_crus_flex_form
			enabled <= enabled true
			node <= dict_node $hyoo_crus_dict
	Text $mol_textarea
		enabled <= enabled true
		value? <=> text? \
	List <= List_drop $mol_drop
		adopt? <=> list_item_adopt? null
		receive? <=> list_receive? null
		allow /
			\move
			\copy
		Sub <= List_items $mol_view
			sub <= list_items /
				<= List_item*0 <= List_item_drop* $mol_drop
					adopt? <=> list_item_adopt? null
					receive? <=> list_item_receive*? null
					allow /
						\move
						\copy
					Sub <= List_item_drag* $mol_drag
						end? <=> list_item_drag_end*? null
						transfer *
							text/plain <= list_item_value* \
							text/html <= list_item_html* \
							text/uri-list <= list_item_uri* \
						Sub <= List_item_dump* $hyoo_crus_sand_dump
							land <= land
							sand <= list_sand* $hyoo_crus_sand
				<= List_pick $mol_select
					enabled <= enabled true
					value? <=> list_pick? null
					options <= ref_options
					option_label* <= ref_label* \
				<= List_item_add $mol_button_minor
					enabled <= enabled true
					click? <=> list_item_add? null
					title \+
